/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  background-color: #eaeaea;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
} */

.microphone {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  /* height: 2em;
  width: 2em; */
  padding: 0.9em;
  border-radius: 50%;
  margin: auto;
  /* font-size: 0.7em; */
  cursor: pointer;
}

/* .microphone > .fa-microphone {
  font-size: 1.9em;
} */

.play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  border: 0.13em solid #6f4ad2;
  position: relative;
  cursor: pointer;
  /* font-size: 0.8em; */
}

/* .play-button > .fa-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  font-size: 1.05em;
} */

.recorder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** box **/
.box-wrapper {
}

.box-wrapper--hidden {
  visibility: hidden;
  position: absolute;
}
.box {
  /* font-size: 3em; */
  /* width: 12em; */
  /* height: 5em; */
  width: 100%;
  background: #061732;
  box-shadow: 1.25em 1.25em 3.125em rgba(6, 23, 50, 0.2);
  border-radius: 0.5em;
  font-size: 1.1em;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.55em;
  font-family: "Roboto", sans-serif;
  /* font-size: 400; */
  color: #fff;
}

.stop-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  border: 0.13em solid #6f4ad2;
  position: relative;
  cursor: pointer;
  font-size: 0.8em;
}

.play-button > .fa-pause {
  font-size: 0.7em;
}

/* .box::before {
  content: "";
  position: absolute;
  bottom: -3%;
  right: 6%;
  width: 0.6em;
  height: 0.6em;
  background: #061732;
  border-radius: 0.1em;
  transform: rotate(45deg);
} */

.box__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.box__bottom {
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.5em;
  justify-content: center;
  width: 220px;
}

.box__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box__text {
  color: #fff;
  font-size: 0.7em;
  /* margin-left: 1em; */
}

.box__text--status {
  color: rgba(255, 255, 255, 0.6);
}

.box__text--total-time {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.6em;
}

.box__text--time-spent {
  /* font-size: 0.84em;   */
  font-weight: 700;
  margin-right: 0.3em;
}

.box__timer {
  line-height: 1em;
  margin-left: 0.3em;
}

.box__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4em;
  justify-content: space-between;
}

.box-button {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.37em;
  color: #fff;
  border: 0;
  font-size: 1.4em;
  padding: 0.6em 1.6em;
  line-height: 0.95em;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-left: 1em;
}

.box-button--purple {
  color: #fff;
  background: #6f4ad2;
}

.box__recorder {
  width: 100%;
}

.box__recording {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.box__progress {
  flex: 1;
  margin-left: 2.5em !important;
}

.box__progress {
  -webkit-appearance: none !important;
  margin: 0;
  padding: 0;
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
  height: 0.2em;
  font-size: 0.6em;
  width: 100%;
}

.box__progress::-ms-fill-lower {
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
}

.box__progress::-ms-fill-upper {
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
}

.box__progress::-moz-range-track {
  border: none;
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
}

.box__progress::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 0.6em;
  height: 0.6em;
  background: linear-gradient(45deg, #b721ff 0%, #21d4fd 100%);
  transform: rotate(45deg);
  cursor: pointer;
}

.box__progress::-moz-range-thumb {
  width: 0.6em;
  height: 0.6em;
  background: linear-gradient(45deg, #b721ff 0%, #21d4fd 100%);
  transform: rotate(45deg);
  cursor: pointer;
}

.box__progress::-ms-thumb {
  -webkit-appearance: none !important;
  width: 0.6em;
  height: 0.6em;
  background: linear-gradient(45deg, #b721ff 0%, #21d4fd 100%);
  transform: rotate(45deg);
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .box {
    font-size: 1.1em;
  }
}
