.btn-add {
  position: absolute;
  z-index: 10;
  right: 130px;
  bottom: 15px;
  padding: 10px;
  background: #090d2c;
  color: white;
  border: 1px solid transparent;
  cursor: pointer;
}

.icon {
  position: absolute;
  z-index: 10;
  right: 10px;
  bottom: 15px;
  background: #090d2c;
  padding: 5px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 25vh;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.delete_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 20px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: 25vh;
  width: 35vw;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.modal h2{
  color: whitesmoke;
}

.textfield {
  outline: none;
  color: #090d2c;
  width: 250px;
  padding: 10px;
  font-family: "Plus Jakarta Display","Helvetica","Arial","sans-serif";
  border: 2px solid linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
  border-radius: 5px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breadcrumb {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 15px;
  padding: 5px;
  background: #090d2c;
  color: white !important;
  border: 1px solid transparent;
  cursor: pointer;
}

.window {
  width: 55vw;
  height: 75vh;
}

.header {
  background: lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px 5px 0px 0px;
  padding: 8px 20px;
  margin-top: 10px;
}

.header-item {
  background: gray;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
}

.main {
  display: flex;
  height: 65vh;
}

.main-first {
  flex: 0.5;
  position: relative;
}

.main-second {
  flex: 0.5;
  background: white;
}

.overlay_text {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  padding: 0 20px;
  word-wrap: break-word;
  text-align: justify;
}

.open_ended {
  margin-top: 50%;
  margin-left: auto;
  margin-right: auto;
  color: #090d2c;
}

.open_ended_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(10, 15, 50);
  background: rgb(10, 15, 50);
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
  min-width: 80px;
  margin: 0px 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

.askModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadModal {
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}