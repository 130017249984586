.custom-seek-bar-container {
  display: flex;
  align-items: center;
}

.blurred-content {
  filter: blur(5px);
  pointer-events: none;
  position: relative;
}
.custom-seek-bar {
  /* width: 200px; */
  /* Adjust the width as needed */
  /* height: 50px; */
  /* Adjust the height as needed */
  margin: 0 5px;
  /* Add margin as needed */
}

.loading_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 30px 50px;
  /* text-align: center; */
  display: flex;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: 320px;
  min-width: fit-content;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}