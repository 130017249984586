.table_view {
  border-collapse: collapse;
  border-radius: 10px;
  margin: 5px 30px;
}

.table_head {
  background-color: #0075ff;
  color: white;
}

.table_body tr:nth-child(odd) {
  background-color: #fff;
}

.table_body tr:nth-child(even) {
  background-color: #eee;
}

.table_row, .table_data {
	border: 1px solid black;
	padding: 8px;
}