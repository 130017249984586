.app {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.layout {
  padding: 0.5rem;
  scroll-behavior: smooth;
  width: 100%;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: flex-end;
}

.button_arrow {
  border-radius: 100%;
  padding: 8px 10px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number {
  border-radius: 100%;
  padding: 8px 13px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number.active {
  background: linear-gradient(310deg, rgb(0, 117, 255), rgb(33, 212, 253));
  color: #fff;
}

.button_number:hover {
  background: white;
  color: black;
}

.button_arrow:hover {
  background: white;
  color: black;
}

.button_arrow:disabled,
.button_arrow[disabled]{
  display: none;
}

.chat-button-container {
  position: fixed;
  bottom: 50px;
  right: 40px;
}

.chat-button {
  width: 200px; 
  height: 200px;
}

.chat-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.chatwindow_footer {
  display: 'flex';
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  width: 300;
}

.video-container {
  position: relative;
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .layout {
    padding: 0;
  } 

  .video-container {
    width: 150px;
    height: 150px;
  }
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: whitesmoke 0px 0px 10px 3px;
  margin-top: 10px;
  background: #fff;
  transition: opacity 0.5s ease-in-out 0s;
}

.video-container.loaded video {
  opacity: 1;
}
