.text-updater-node {
  border: 1px solid #eee;
  padding: 10px 20px;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 0px 10px 2px #fff;
  min-width: 150px;
}

.text-updater-node > div {
  text-align: center;
}

.text-updater-node > div > h3 {
  margin-right: 5px;
  font-weight: 400;
}
