.audioplayer_button {
  min-width: "150px";
  color: 'black';
  background: "white";
  outline: "none";
  padding: "12px 10px";
  border-radius: "11px";
}

.confirm_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 20px 50px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: 25vh;
  min-width: 35vw;
  overflow-y: auto;
  max-height: 80vh;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.loading_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 30px 50px;
  /* text-align: center; */
  display: flex;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: fit-content;
  min-width: fit-content;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.blinking-icon {
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blurred-content {
  filter: blur(5px);
  pointer-events: none;
  position: relative;
}
