.g-recaptcha {
  transform: scale(0.87);
  transform-origin: 0 0;
}

.g-recaptcha-signin {
  transform: scale(0.8);
  transform-origin: 0 0;
}

@media (max-width: 768px) {
  .g-recaptcha {
    transform: scale(0.85);
  }

  .g-recaptcha-signin {
    transform: scale(0.78);
    transform-origin: 0 0;
  }
}