@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.layout {
  padding: 0.5rem;
  scroll-behavior: smooth;
  width: 100%;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: flex-end;
}

.button_arrow {
  border-radius: 100%;
  padding: 8px 10px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number {
  border-radius: 100%;
  padding: 8px 13px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number.active {
  background: linear-gradient(310deg, rgb(0, 117, 255), rgb(33, 212, 253));
  color: #fff;
}

.button_number:hover {
  background: white;
  color: black;
}

.button_arrow:hover {
  background: white;
  color: black;
}

.button_arrow:disabled,
.button_arrow[disabled]{
  display: none;
}

.chat-button-container {
  position: fixed;
  bottom: 50px;
  right: 40px;
}

.chat-button {
  width: 200px; 
  height: 200px;
}

.chat-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.chatwindow_footer {
  display: 'flex';
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  width: 300;
}

.video-container {
  position: relative;
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .layout {
    padding: 0;
  } 

  .video-container {
    width: 150px;
    height: 150px;
  }
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: whitesmoke 0px 0px 10px 3px;
  margin-top: 10px;
  background: #fff;
  transition: opacity 0.5s ease-in-out 0s;
}

.video-container.loaded video {
  opacity: 1;
}


.g-recaptcha {
  transform: scale(0.87);
  transform-origin: 0 0;
}

.g-recaptcha-signin {
  transform: scale(0.8);
  transform-origin: 0 0;
}

@media (max-width: 768px) {
  .g-recaptcha {
    transform: scale(0.85);
  }

  .g-recaptcha-signin {
    transform: scale(0.78);
    transform-origin: 0 0;
  }
}
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  background-color: #eaeaea;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
} */

.microphone {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  /* height: 2em;
  width: 2em; */
  padding: 0.9em;
  border-radius: 50%;
  margin: auto;
  /* font-size: 0.7em; */
  cursor: pointer;
}

/* .microphone > .fa-microphone {
  font-size: 1.9em;
} */

.play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  border: 0.13em solid #6f4ad2;
  position: relative;
  cursor: pointer;
  /* font-size: 0.8em; */
}

/* .play-button > .fa-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  font-size: 1.05em;
} */

.recorder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** box **/
.box-wrapper {
}

.box-wrapper--hidden {
  visibility: hidden;
  position: absolute;
}
.box {
  /* font-size: 3em; */
  /* width: 12em; */
  /* height: 5em; */
  width: 100%;
  background: #061732;
  box-shadow: 1.25em 1.25em 3.125em rgba(6, 23, 50, 0.2);
  border-radius: 0.5em;
  font-size: 1.1em;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.55em;
  font-family: "Roboto", sans-serif;
  /* font-size: 400; */
  color: #fff;
}

.stop-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  border: 0.13em solid #6f4ad2;
  position: relative;
  cursor: pointer;
  font-size: 0.8em;
}

.play-button > .fa-pause {
  font-size: 0.7em;
}

/* .box::before {
  content: "";
  position: absolute;
  bottom: -3%;
  right: 6%;
  width: 0.6em;
  height: 0.6em;
  background: #061732;
  border-radius: 0.1em;
  transform: rotate(45deg);
} */

.box__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.box__bottom {
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.5em;
  justify-content: center;
  width: 220px;
}

.box__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box__text {
  color: #fff;
  font-size: 0.7em;
  /* margin-left: 1em; */
}

.box__text--status {
  color: rgba(255, 255, 255, 0.6);
}

.box__text--total-time {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.6em;
}

.box__text--time-spent {
  /* font-size: 0.84em;   */
  font-weight: 700;
  margin-right: 0.3em;
}

.box__timer {
  line-height: 1em;
  margin-left: 0.3em;
}

.box__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4em;
  justify-content: space-between;
}

.box-button {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.37em;
  color: #fff;
  border: 0;
  font-size: 1.4em;
  padding: 0.6em 1.6em;
  line-height: 0.95em;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-left: 1em;
}

.box-button--purple {
  color: #fff;
  background: #6f4ad2;
}

.box__recorder {
  width: 100%;
}

.box__recording {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.box__progress {
  flex: 1 1;
  margin-left: 2.5em !important;
}

.box__progress {
  -webkit-appearance: none !important;
  margin: 0;
  padding: 0;
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
  height: 0.2em;
  font-size: 0.6em;
  width: 100%;
}

.box__progress::-ms-fill-lower {
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
}

.box__progress::-ms-fill-upper {
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
}

.box__progress::-moz-range-track {
  border: none;
  background: linear-gradient(90deg, #b721ff 0%, #21d4fd 100%);
}

.box__progress::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 0.6em;
  height: 0.6em;
  background: linear-gradient(45deg, #b721ff 0%, #21d4fd 100%);
  transform: rotate(45deg);
  cursor: pointer;
}

.box__progress::-moz-range-thumb {
  width: 0.6em;
  height: 0.6em;
  background: linear-gradient(45deg, #b721ff 0%, #21d4fd 100%);
  transform: rotate(45deg);
  cursor: pointer;
}

.box__progress::-ms-thumb {
  -webkit-appearance: none !important;
  width: 0.6em;
  height: 0.6em;
  background: linear-gradient(45deg, #b721ff 0%, #21d4fd 100%);
  transform: rotate(45deg);
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .box {
    font-size: 1.1em;
  }
}

.audioplayer_button {
  min-width: "150px";
  color: 'black';
  background: "white";
  outline: "none";
  padding: "12px 10px";
  border-radius: "11px";
}

.confirm_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 20px 50px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: 25vh;
  min-width: 35vw;
  overflow-y: auto;
  max-height: 80vh;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.loading_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 30px 50px;
  /* text-align: center; */
  display: flex;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.blinking-icon {
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blurred-content {
  filter: blur(5px);
  pointer-events: none;
  position: relative;
}

.blurred-content {
  filter: blur(5px);
  pointer-events: none;
  position: relative;
}

.text-updater-node {
  border: 1px solid #eee;
  padding: 10px 20px;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 0px 10px 2px #fff;
  min-width: 150px;
}

.text-updater-node > div {
  text-align: center;
}

.text-updater-node > div > h3 {
  margin-right: 5px;
  font-weight: 400;
}

.btn-add {
  position: absolute;
  z-index: 10;
  right: 130px;
  bottom: 15px;
  padding: 10px;
  background: #090d2c;
  color: white;
  border: 1px solid transparent;
  cursor: pointer;
}

.icon {
  position: absolute;
  z-index: 10;
  right: 10px;
  bottom: 15px;
  background: #090d2c;
  padding: 5px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 25vh;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.delete_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 20px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: 25vh;
  width: 35vw;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

.modal h2{
  color: whitesmoke;
}

.textfield {
  outline: none;
  color: #090d2c;
  width: 250px;
  padding: 10px;
  font-family: "Plus Jakarta Display","Helvetica","Arial","sans-serif";
  border: 2px solid linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
  border-radius: 5px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breadcrumb {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 15px;
  padding: 5px;
  background: #090d2c;
  color: white !important;
  border: 1px solid transparent;
  cursor: pointer;
}

.window {
  width: 55vw;
  height: 75vh;
}

.header {
  background: lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px 5px 0px 0px;
  padding: 8px 20px;
  margin-top: 10px;
}

.header-item {
  background: gray;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
}

.main {
  display: flex;
  height: 65vh;
}

.main-first {
  flex: 0.5 1;
  position: relative;
}

.main-second {
  flex: 0.5 1;
  background: white;
}

.overlay_text {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  padding: 0 20px;
  word-wrap: break-word;
  text-align: justify;
}

.open_ended {
  margin-top: 50%;
  margin-left: auto;
  margin-right: auto;
  color: #090d2c;
}

.open_ended_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(10, 15, 50);
  background: rgb(10, 15, 50);
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
  min-width: 80px;
  margin: 0px 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

.askModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadModal {
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.table_view {
  border-collapse: collapse;
  border-radius: 10px;
  margin: 5px 30px;
}

.table_head {
  background-color: #0075ff;
  color: white;
}

.table_body tr:nth-child(odd) {
  background-color: #fff;
}

.table_body tr:nth-child(even) {
  background-color: #eee;
}

.table_row, .table_data {
	border: 1px solid black;
	padding: 8px;
}
.table_view {
  border-collapse: collapse;
  border-radius: 10px;
  margin: 5px 30px;
}

.table_head {
  background-color: #0075ff;
  color: white;
}

.table_body tr:nth-child(odd) {
  background-color: #fff;
}

.table_body tr:nth-child(even) {
  background-color: #eee;
}

.table_row, .table_data {
	border: 1px solid black;
	padding: 8px;
}
.blurred-content {
  filter: blur(5px);
  pointer-events: none;
  position: relative;
}

.custom-seek-bar-container {
  display: flex;
  align-items: center;
}

.blurred-content {
  filter: blur(15px);
  pointer-events: none;
  position: relative;
}
.custom-seek-bar {
  /* width: 200px; */
  /* Adjust the width as needed */
  /* height: 50px; */
  /* Adjust the height as needed */
  margin: 0 5px;
  /* Add margin as needed */
}

.loading_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 30px 50px;
  /* text-align: center; */
  display: flex;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: 320px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}
.custom-seek-bar-container {
  display: flex;
  align-items: center;
}

.blurred-content {
  filter: blur(5px);
  pointer-events: none;
  position: relative;
}
.custom-seek-bar {
  /* width: 200px; */
  /* Adjust the width as needed */
  /* height: 50px; */
  /* Adjust the height as needed */
  margin: 0 5px;
  /* Add margin as needed */
}

.loading_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 99.41%, rgba(10, 14, 35, 0.49) 76.65%);
  color: whitesmoke;
  outline: none;
  padding: 30px 50px;
  /* text-align: center; */
  display: flex;
  /* justify-content: space-evenly;
  align-items: center; */
  min-height: 320px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* box-shadow: "rgb(54, 68, 97) 0px 0px 10px 2px"; */
  border-radius: '5px';
}

::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  overflow-y: scroll;
}
/* Full Calendar */

.fc-toolbar-title {
  color: white !important;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
div.fc-daygrid-event-harness {
  top: unset;
}
.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
  top: 0px;
  bottom: unset;
}
.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
  border-right: 0px !important;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0px solid transparent !important;
}

.fc-col-header-cell.fc-day.fc-day-mon {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-tue {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-wed {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-thu {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-fri {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-sat {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-sun {
  color: white;
  border-left: 0px;
  border-right: 0px;
}

.fc-daygrid-event {
  border-radius: 10px !important;
  text-align: center !important;
  font-family: "Plus Jakarta Display" !important;
}
.fc-day {
  color: white;
}
.fc-day.fc-day-mon {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-tue {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-wed {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-thu {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-fri {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-sat {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-sun {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid > td {
  border: 0px;
}

.fc .fc-scrollgrid-section > td {
  border: 0;
}

/* React Kanban */
body .react-kanban-column {
  background: linear-gradient(
    127.09deg,
    rgba(6, 11, 40, 0.94) 19.41%,
    rgba(10, 14, 35, 0.49) 76.65%
  );
  background-color: unset !important;
  border-radius: 15px;
}
.react-kanban-column {
  width: 500px;
}
.react-kanban-card-adder-form {
  width: 100%;
  background-color: red;
  border-radius: 15px;
}
.react-kanban-card-adder-button {
  display: none;
  background-color: red !important;
  border-radius: 15px;
}

/* Text Editor */

body .ql-toolbar.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-container.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-snow .ql-picker-label {
  color: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.92) !important;
}

/* .ql-snow .ql-picker-label:hover {
    color: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-stroke:hover {
    stroke: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-fill:hover {
    fill: rgba(255, 255, 255, 0.92) !important;
  } */

.ql-picker-options {
  background: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

.ql-container.ql-snow {
  color: rgb(160, 174, 192);
  min-height: 88px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

